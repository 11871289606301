<template>
  <v-app-bar app flat>
    <a :href="getLegalEntity.url" class="text-decoration-none">
      <div class="d-flex align-center">
        <TheLogo :width="logoWidth" :height="APP_BAR_HEIGHT - 25" />
        <span
          class="font-weight-bold font-italic companyPrimary--text"
          :class="getLegalEntity.headerSpanClass"
          :style="getLegalEntity.headerSpanStyle"
        >
          CAREERS
        </span>
      </div>
    </a>
    <v-spacer />
    <v-btn text :to="{ name: 'login' }">
      <v-icon left>mdi-login-variant</v-icon>
      Login
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import TheLogo from "@/components/TheLogo.vue";

export default {
  name: "TheHeader",
  components: {
    TheLogo,
  },
  data: () => ({
    APP_BAR_HEIGHT: 64,
  }),
  computed: {
    ...mapGetters("domain", ["getLegalEntity"]),
    logoWidth() {
      return this.$vuetify.breakpoint.name === "xs" ? 100 : 200;
    },
  },
  methods: {
    logApplicationMeasures() {
      console.log(this.$vuetify.application.bar);
      console.log(this.$vuetify.application.bottom);
      console.log(this.$vuetify.application.footer);
      console.log(this.$vuetify.application.insetFooter);
      console.log(this.$vuetify.application.left);
      console.log(this.$vuetify.application.right);
      console.log(this.$vuetify.application.top);
    },
  },
};
</script>
