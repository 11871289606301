export default {
  namespaced: true,
  state: {
    legalEntityMap: {
      localhost: {
        label: "[DEV] Iason",
        longLabel: "Iason Italia SRL",
        image: "@/components/svg/list/localLogo.svg",
        recruitmentMail: "recruitment@localhost.eu",
        url: "https://www.iasonltd.com/",
        headerSpanStyle: "margin-left: -36px !important; margin-top: -17px",
        headerSpanClass: "text-h5 d-none d-sm-block",
        colors: {
          primary: "#007BFF",
          secondary: "#424242",
          accent: "#82B1FF",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFC107",
          companyPrimary: "#ee8617",
          companySecondary: "#d1e6fc",
          companyTertiary: "#0e0801",
          companyPrimaryVeryLight: "#fef8f1",
          companyPrimaryLight: "#fdf3e7",
          companySecondaryLight: "#f1f7fe",
        },
      },
      "careers.iasonltd.local": {
        label: "[QA] Iason ",
        longLabel: "Iason Italia SRL",
        image: "@/components/svg/list/iasonLogo.svg",
        recruitmentMail: "recruitment@iasonltd.eu",
        url: "https://www.iasonltd.com/",
        headerSpanStyle: "margin-left: -36px !important; margin-top: -17px",
        headerSpanClass: "text-h5 d-none d-sm-block",
        colors: {
          primary: "#007BFF",
          secondary: "#424242",
          accent: "#82B1FF",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFC107",
          companyPrimary: "#ee8617",
          companySecondary: "#d1e6fc",
          companyTertiary: "#0e0801",
          companyPrimaryVeryLight: "#fef8f1",
          companyPrimaryLight: "#fdf3e7",
          companySecondaryLight: "#f1f7fe",
        },
      },
      "careers.iasonltd.com": {
        label: "Iason",
        longLabel: "Iason Italia SRL",
        image: "@/components/svg/list/iasonLogo.svg",
        recruitmentMail: "recruitment@iasonltd.eu",
        url: "https://www.iasonltd.com/",
        headerSpanStyle: "margin-left: -36px !important; margin-top: -17px",
        headerSpanClass: "text-h5",
        colors: {
          primary: "#007BFF",
          secondary: "#424242",
          accent: "#82B1FF",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFC107",
          companyPrimary: "#ee8617",
          companySecondary: "#d1e6fc",
          companyTertiary: "#0e0801",
          companyPrimaryVeryLight: "#fef8f1",
          companyPrimaryLight: "#fdf3e7",
          companySecondaryLight: "#f1f7fe",
        },
      },
      "careers.technesthai.com": {
        label: "Technesthai",
        longLabel: "Technesthai Italia SRL",
        image: "@/components/svg/list/technesthaiLogo.svg",
        recruitmentMail: "recruitment@technesthai.eu",
        url: "https://technesthai.com/",
        headerSpanStyle: "margin-left: -30px !important; margin-top: -40px",
        headerSpanClass: "text-caption",
        colors: {
          primary: "#007BFF",
          secondary: "#424242",
          accent: "#82B1FF",
          error: "#FF5252",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FFC107",
          companyPrimary: "#b46cf8",
          companySecondary: "#89B2FF",
          companyTertiary: "#0e0801",
          companyPrimaryVeryLight: "#F1E3FF",
          companyPrimaryLight: "#E7D0FD",
          companySecondaryLight: "#C5D9FF",
        },
      },
    },
  },
  getters: {
    getLegalEntity(state) {
      return state.legalEntityMap[window.location.hostname];
    },
  },
};
